<template>
  <scroller>
    <h1 class="text-primary pb-50 mt-2">
      {{ $t('pages.settings.name') }}
    </h1>

    <div class="mt-3 mb-3">
      <b-card
        v-if="user && farmer"
        :bg-variant="'primary'"
        text-variant="white"
        class="w-100 card-gradient card-rounded"
      >
        <div class="d-flex align-items-center py-25">
          <b-avatar
            size="75px"
            style="border: 4px solid white;"
            :src="user.avatar"
          />
          <div class="text-white pl-25 ml-1">
            <h5 class="text-white m-0">
              {{ user.firstname.trim() }} {{ user.lastname.trim() }}
            </h5>
            <p class="font-weight-light m-0">
              {{ farmer.name }}
            </p>
          </div>
        </div>
      </b-card>

      <b-list-group>
        <farmer-change-item v-if="hasMultipleFarmers" />
        <farmer-always-choice-item v-if="hasMultipleFarmers" />
        <logout-item />
      </b-list-group>

      <div class="mt-2" />

      <b-list-group>
        <version-item />
      </b-list-group>
    </div>
  </scroller>
</template>

<script>
import {
  BCard, BAvatar, BListGroup,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import LogoutItem from '@/views/pages/Settings/components/LogoutItem.vue'
import FarmerChangeItem from '@/views/pages/Settings/components/FarmerChangeItem.vue'
import VersionItem from '@/views/pages/Settings/components/VersionItem.vue'
import FarmerAlwaysChoiceItem from '@/views/pages/Settings/components/FarmerAlwaysChoiceItem.vue'

export default {
  components: {
    BCard,
    BAvatar,
    BListGroup,
    'logout-item': LogoutItem,
    'farmer-change-item': FarmerChangeItem,
    'farmer-always-choice-item': FarmerAlwaysChoiceItem,
    'version-item': VersionItem,
  },
  data() {
    return {}
  },
  computed: {
    /** @type {userProfile} user */
    ...mapGetters({
      user: 'auth/getUser',
      farmer: 'farmers/getDefaultFarmer',
      hasMultipleFarmers: 'farmers/hasMultipleFarmers',
    }),
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

</style>
