<template>
  <b-list-group-item
    class="d-flex justify-content-between align-items-center"
    @action="$router.push({name:ROUTE_NAME_FARMERS})"
  >
    {{ $t('therms.farmer-change') }}
    <b-list-group-item-button
      disable
      color="primary"
    ><i class="fa-solid fa-house-window" />
    </b-list-group-item-button>
  </b-list-group-item>
</template>

<script>
import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'
import ListGroupItemButton from '@core/components/list-group/ListGroupItemButton.vue'
import { NAME_FARMERS } from '@/router/routes/farmers'

export default {
  components: {
    'b-list-group-item': ListGroupItem,
    'b-list-group-item-button': ListGroupItemButton,
  },
  data() {
    return {}
  },
  computed: {
    ROUTE_NAME_FARMERS() {
      return NAME_FARMERS
    },
  },
  methods: {},
}
</script>
