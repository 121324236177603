<template>
  <b-list-group-item
    class="d-flex justify-content-between align-items-center"
    :disabled="true"
  >
    {{ $t('therms.app-version') }}
    <span class="font-weight-bolder">v <app-version /></span>
  </b-list-group-item>
</template>

<script>import ListGroupItem from '@core/components/list-group/ListGroupItem.vue'

import AppVersion from '@core/components/app-version/AppVersion.vue'

export default {
  components: {
    'app-version': AppVersion,
    'b-list-group-item': ListGroupItem,
  },
  data() {
    return {}
  },
  computed: {
  },
  methods: {},
}
</script>
